import { format, parseISO } from "date-fns"
import { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import { Container } from "../components/container"
import Grid from "@mui/material/Grid"
import LoadingScreen from "../components/loading"
import NotFoundImage from "../assets/not_found.png"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { dataProvider } from "../App"
import numeral from "numeral"

const TREND_FIELDS = [
    {
        name: "spend_upper",
        order: "desc",
        description: "Spending Trends",
    },
]

const trim_field = (field) => {
    return field.split("_").slice(0, 2).join("_")
}

const TrendsBox = ({
    field,
    order = "desc",
    description,
    key_seats = false,
}) => {
    const [trendData, setTrendData] = useState()
    const fieldCore = trim_field(field)

    useEffect(() => {
        dataProvider
            .getTrends({
                sort_field: field,
                order: order,
                key_seats: key_seats,
            })
            .then((data) => {
                setTrendData(data)
            })
    }, [field, order, key_seats])

    return (
        <Card
            sx={{ minWidth: 275, margin: "0.5em" }}
            style={{ marginBottom: "1em" }}
        >
            <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    {description}
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Candidate</TableCell>
                            <TableCell>Electorate</TableCell>
                            <TableCell align="right">Yesterday</TableCell>
                            <TableCell align="right">Today</TableCell>
                            <TableCell align="right">Trend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trendData ? (
                            trendData.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell scope="row">
                                        <a
                                            alt="candidate link"
                                            href={`#/candidates/${row.candidate_id}/show`}
                                        >
                                            {row.candidate_name}
                                        </a>
                                    </TableCell>
                                    <TableCell align="left">
                                        <a
                                            alt="electorate link"
                                            href={`#/electorates/${row.electorate_id}/show`}
                                        >
                                            {row.electorate_name}
                                        </a>
                                    </TableCell>
                                    <TableCell align="right">
                                        {numeral(
                                            row[fieldCore + "_yesterday"],
                                        ).format("0,0")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {numeral(row[fieldCore]).format("0,0")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {numeral(
                                            row[fieldCore + "_change"],
                                        ).format("0,0")}{" "}
                                        %
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <div style={{ marginTop: "1em" }}>Loading ..</div>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

const StatsBox = ({ trendData, description }) => {
    return (
        <Card
            sx={{ minWidth: 275, margin: "0.5em" }}
            style={{ marginBottom: "1em" }}
        >
            <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    {description}
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Candidate</TableCell>
                            <TableCell>Electorate</TableCell>
                            <TableCell align="right">Spend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trendData ? (
                            trendData.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell scope="row">
                                        <a
                                            alt="candidate link"
                                            href={`#/candidates/${row.candidate_id}/show`}
                                        >
                                            {row.name}
                                        </a>
                                    </TableCell>
                                    <TableCell align="left">
                                        <a
                                            alt="electorate link"
                                            href={`#/electorates/${row.electorate_id}/show`}
                                        >
                                            {row.electorate}
                                        </a>
                                    </TableCell>
                                    <TableCell align="right">
                                        {numeral(row["spend"]).format("$ 0,0")}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <div style={{ marginTop: "1em" }}>No Records</div>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

const Homepage = ({ records }) => {
    const [topTrendDay, setTopTrendDay] = useState()
    const [topTrendWeek, setTopTrendWeek] = useState()

    useEffect(() => {
        dataProvider
            .getTrendsTop({
                days: 1,
            })
            .then((data) => {
                setTopTrendDay(data)
            })

        dataProvider
            .getTrendsTop({
                days: 7,
            })
            .then((data) => {
                setTopTrendWeek(data)
            })
    }, [])

    return (
        <Container>
            <Typography variant="h4">Dashboard</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    p: 1,
                    m: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                }}
            >
                {!topTrendDay || (!topTrendWeek && <LoadingScreen />)}
                <StatsBox
                    trendData={topTrendDay}
                    description="Top Spenders 24h"
                />
                <StatsBox
                    trendData={topTrendWeek}
                    description="Top Spenders 7 days"
                />
                <TrendsBox
                    field={"spend_upper_change"}
                    order={"desc"}
                    description="Spending Increases Key Seats"
                    key_seats={true}
                />
                <TrendsBox
                    field={"spend_upper_change"}
                    order={"asc"}
                    description="Spending Decreases Key Seats"
                    key_seats={true}
                />
                <TrendsBox
                    field={"active_ads_change"}
                    order={"desc"}
                    description="Active Ads Key Seats"
                    key_seats={true}
                />
                <TrendsBox
                    field={"spend_upper_change"}
                    order={"desc"}
                    description="Spending Increases"
                />
                <TrendsBox
                    field={"spend_upper_change"}
                    order={"asc"}
                    description="Spending Decreases"
                />
                <TrendsBox
                    field={"active_ads_change"}
                    order={"desc"}
                    description="Active Ads"
                />
            </Box>
        </Container>
    )
}

export default Homepage
