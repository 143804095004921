import * as React from "react"

import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Datagrid,
    Edit,
    EditGuesser,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleList,
    SimpleShowLayout,
    TextField,
    TextInput,
    UrlField,
    useEditContext,
    useListContext,
    useRecordContext,
    useShowContext,
    useShowController,
} from "react-admin"

import AdsElectorate from "./ads_electorate"
import { Container } from "../components/container"
import ListActionsTopToolbar from "../components/list_actions"
import PostIcon from "@mui/icons-material/Book"
import { PostPagination } from "."
import Typography from "@mui/material/Typography"

const electorateFilter = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput
        source="state_id"
        label="State"
        reference="states"
        sort={{ field: "name", order: "ASC" }}
        perPage={10}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <ReferenceInput
        source="party_held"
        label="Party Held"
        reference="partygroups"
        perPage={50}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <SelectInput
        source="seat_type"
        choices={[
            { id: "house", name: "House" },
            { id: "senate", name: "Senate" },
        ]}
        alwaysOn
    />,
    <BooleanInput optionText="Key Races" source="key_race" alwaysOn />,
]

const ElectorateTitle = () => {
    const record = useRecordContext()
    const { isLoading } = useShowContext()

    return !isLoading && record && record.name ? (
        <span>{record.name}</span>
    ) : undefined
}

export const ElectoratesList = () => (
    <Container>
        <Typography variant="h4">Seats</Typography>
        <List
            filters={electorateFilter}
            pagination={<PostPagination />}
            perPage={25}
            actions={<ListActionsTopToolbar />}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" />
                <TextField source="state_id" />
                <TextField source="seat_type" />
                <BooleanField source="key_race" />
                <ReferenceField source="party_held" reference="partygroups">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="margin" />
            </Datagrid>
        </List>
    </Container>
)

const ElectoratesShow = () => {
    const { record } = useShowController()

    return (
        <Container>
            <Typography variant="h4">{record && record.name}</Typography>
            <Show title={record && record.name}>
                <SimpleShowLayout sx={{ maxWidth: 500, width: 500 }}>
                    <TextField source="id" disabled fullWidth />
                    <TextField source="name" fullWidth />
                    <TextField source="seat_type" disabled />
                    <TextField source="state_id" />
                    <BooleanField source="key_race" label="Key Race" />
                    <NumberField source="margin" label="Current Margin" />
                    <ReferenceField
                        source="party_held"
                        reference="partygroups"
                        perPage={100}
                    >
                        <TextField source="name" label="Held By" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
            <Typography
                variant="h4"
                style={{ marginTop: "2em", marginBottom: "1em" }}
            >
                Candidates
            </Typography>
            {record && (
                <List
                    resource="candidates"
                    filter={{
                        electorate_id: record.id,
                        indie_watch: undefined,
                        sort: { sort_field: "surname", sort_order: "ASC" },
                    }}
                    pagination={false}
                    filters={[]}
                    actions={false}
                >
                    <Datagrid rowClick="show" bulkActionButtons={false}>
                        <TextField source="surname" />
                        <TextField source="firstname" />
                        <ReferenceField
                            source="electorate_id"
                            reference="electorates"
                            perPage={250}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField
                            source="party_id"
                            reference="parties"
                            perPage={250}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <BooleanField
                            source="indie_watch"
                            label="Indie Watch"
                        />
                        <UrlField source="facebook" />
                        <TextField source="facebook_id" />
                    </Datagrid>
                </List>
            )}
            <Typography
                variant="h4"
                style={{ marginTop: "2em", marginBottom: "1em " }}
            >
                Ad Statistics
            </Typography>
            {record && <AdsElectorate electorate_id={record.id} />}
        </Container>
    )
}

const ElectoratesEdit = () => {
    const { record } = useEditContext()

    return (
        <Container>
            <Typography variant="h4">Edit {record && record.name}</Typography>
            <Edit title={record.name}>
                <SimpleForm sx={{ maxWidth: 500 }}>
                    <TextInput source="id" disabled />
                    <TextInput source="name" />
                    <TextInput source="set_type" disabled />
                    <TextInput source="state_id" disabled />
                    <BooleanInput source="key_race" label="Key Race" />
                </SimpleForm>
            </Edit>
        </Container>
    )
}

const settings = {
    list: ElectoratesList,
    show: ElectoratesShow,
    edit: ElectoratesEdit,
    icon: PostIcon,
}

export default settings
