import { useEffect, useState } from "react"

import AdList from "../components/ad_list"
import Box from "@mui/material/Box"
import { Container } from "../components/container"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import LoadingScreen from "../components/loading"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import { dataProvider } from "../App"
import { useNotify } from "react-admin"

const AdsLatest = () => {
    const [ad_data, setAdData] = useState()
    const [ad_source, setAdSource] = useState("all")
    const notify = useNotify()

    useEffect(() => {
        dataProvider
            .getAdsList({
                pagination: { page: 1, perPage: 250 },
                sort: { field: "creation_time", order: "DESC" },
                ad_source,
            })
            .then((data) => {
                setAdData(data)
            })
            .catch((e) => {
                notify("Error getting list of ads")
            })
    }, [ad_source, notify])

    const handleChange = (e) => {
        setAdSource(e.target.value)
    }

    const ad_sources = [
        { id: "facebook", name: "Facebook" },
        { id: "google", name: "Google" },
    ]

    return (
        <Container>
            <Typography variant="h4">Latest Ads</Typography>
            <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                <FormControl fullWidth>
                    <InputLabel id="ad_source">Ad Source</InputLabel>
                    <Select
                        labelId="ad_source"
                        id="ad_source"
                        value={ad_source}
                        label="Ad Source"
                        onChange={handleChange}
                    >
                        <MenuItem key={-1} value={"all"}>
                            All
                        </MenuItem>
                        {ad_sources.map((d, i) => (
                            <MenuItem key={i} value={d.id}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {!ad_data && <LoadingScreen />}
            {ad_data && <AdList records={ad_data} />}
        </Container>
    )
}

export default AdsLatest
