import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import { Container } from "../components/container"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Line } from "react-chartjs-2"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { dataProvider } from "../App"
import { useNotify } from "react-admin"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Candidate Ad Stats",
        },
    },
}

const AdsElectorate = ({ electorate_id, ...rest }) => {
    // const dataProvider = useDataProvider()

    const [chartData, setChartData] = useState(undefined)
    const [show_field, setShowField] = useState("spend_upper")
    const [ad_source, setAdSource] = useState("facebook")
    const notify = useNotify()

    useEffect(() => {
        dataProvider
            .getStatsElectorate({
                id: electorate_id,
                field: show_field,
                ad_source,
            })
            .then((r) => {
                console.log(r)

                setChartData(r)
            })
            .catch((r) => {
                setShowField("spend_upper")
                setAdSource("facebook")
                notify("No results")
            })
    }, [show_field, electorate_id, ad_source])

    const handleChangeField = (event) => {
        setShowField(event.target.value)
    }

    const handleAdSourceChange = (e) => {
        setShowField("spend_upper")
        setAdSource(e.target.value)
    }

    const ad_sources = [
        { id: "facebook", name: "Facebook" },
        { id: "google", name: "Google" },
    ]

    const ad_fields = {
        google: [{ id: "spend_upper", name: "Spend Upper" }],
        facebook: [
            { id: "spend_upper", name: "Spend Upper" },
            { id: "spend_total", name: "Spend (Report)" },
            { id: "audience_upper", name: "Audience" },
            { id: "impressions_upper", name: "Impressions" },
            { id: "active_ads", name: "Active Ads" },
        ],
    }

    return (
        <Container>
            <Typography>Electorate Ad Stats</Typography>
            <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                <FormControl fullWidth>
                    <InputLabel id="ad_source">Ad Source</InputLabel>
                    <Select
                        labelId="ad_source"
                        id="ad_source"
                        value={ad_source}
                        label="Ad Source"
                        onChange={handleAdSourceChange}
                    >
                        {ad_sources.map((d, i) => (
                            <MenuItem key={i} value={d.id}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                <FormControl fullWidth>
                    <InputLabel id="field_show">Statistic</InputLabel>
                    <Select
                        labelId="field_show"
                        id="field_show"
                        value={show_field}
                        label="Field"
                        onChange={handleChangeField}
                    >
                        {ad_fields[ad_source].map((m, i) => (
                            <MenuItem value={m.id} key={i}>
                                {m.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {electorate_id && chartData && (
                <Line options={chartOptions} data={chartData} {...rest} />
            )}
        </Container>
    )
}

export default AdsElectorate
