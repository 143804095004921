import { Button, FilterButton, TopToolbar, useListContext } from "react-admin"

import FilterAltOff from "@mui/icons-material/FilterAltOff"
import React from "react"

const ListActionsTopToolbar = ({ className }) => {
  const { setFilters } = useListContext()

  return (
    <TopToolbar className={className}>
      <FilterButton />
      <Button onClick={() => setFilters({})} label="Clear Filters">
        <FilterAltOff />
      </Button>
    </TopToolbar>
  )
}

export default ListActionsTopToolbar
