import { format, parseISO } from "date-fns"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import FacebookIcon from "@mui/icons-material/Facebook"
import GoogleIcon from "@mui/icons-material/Google"
import Grid from "@mui/material/Grid"
import NotFoundImage from "../assets/not_found.png"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import numeral from "numeral"

const parseDate = (date) => (date ? format(parseISO(date), "do LLL yyyy") : "")

const FormatRangeCurrency = (props) => (
    <FormatRange formatString={"$0,0"} {...props} />
)

const FormatRange = ({ lower, upper, formatString = "0,0" }) => {
    let divider = " < "

    if (lower > 0) {
        divider = " - "
    }

    return (
        <>
            {lower > 0 ? numeral(lower).format(formatString) : ""}
            {divider}
            {numeral(upper).format(formatString)}
        </>
    )
}

const AdIcon = ({ ad_source }) => {
    if (ad_source === "facebook") {
        return (
            <Button variant="contained">
                <FacebookIcon color="blue" fontSize="medium" />
            </Button>
        )
    }

    if (ad_source === "google") {
        return (
            <Button variant="contained">
                <GoogleIcon color="orange" fontSize="medium" />
            </Button>
        )
    }
}

const AdList = ({ records }) => {
    return (
        <>
            <h3>Displaying {records.length} Ads</h3>
            <p>Click on ad creative for details in new window</p>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    p: 1,
                    m: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                }}
            >
                {records.map((record, i) => (
                    <Card
                        key={i}
                        sx={{ minWidth: 275, maxWidth: 400, margin: "0.5em" }}
                        style={{ marginBottom: "1em" }}
                    >
                        <CardContent>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {record.id}
                            </Typography>

                            <Box>
                                <AdIcon ad_source={record.ad_source} />{" "}
                                {record.start_time && !record.end_time ? (
                                    <Button color="success" variant="contained">
                                        Active
                                    </Button>
                                ) : (
                                    <Button color="error" variant="contained">
                                        Inactive
                                    </Button>
                                )}{" "}
                                <span style={{ marginLeft: "2em" }}>
                                    {!record.ad_image_url && record.candidate && (
                                        <h3>
                                            {record.candidate.name_full}:{" "}
                                            {record.candidate.electorate_id}
                                        </h3>
                                    )}
                                    {!record.ad_image_url &&
                                        !record.candidate && (
                                            <h3>{record.page_name}</h3>
                                        )}
                                </span>
                            </Box>
                            <Box>
                                {record.ad_image_url ? (
                                    <a href={record.ad_url} target="_new">
                                        <img
                                            src={record.ad_image_url}
                                            style={{ width: "100%" }}
                                            alt="ad screenhot"
                                        />
                                    </a>
                                ) : (
                                    <a href={record.ad_url} target="_new">
                                        <img
                                            src={NotFoundImage}
                                            style={{ width: "100%" }}
                                            alt="not found"
                                        />
                                    </a>
                                )}
                            </Box>
                            <TableContainer>
                                <Table
                                    sx={{ maxWidth: "100%" }}
                                    aria-label="simple table"
                                    // border="0"
                                >
                                    <TableBody>
                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{ width: "50%" }}
                                            >
                                                <b>Created</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {parseDate(
                                                    record.creation_time,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <b>Start</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {parseDate(record.start_time)}
                                            </TableCell>
                                        </TableRow>
                                        {record.end_time && (
                                            <TableRow
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <b>Ended</b>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {parseDate(record.end_time)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <b>Spend</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormatRangeCurrency
                                                    lower={record.spend_lower}
                                                    upper={record.spend_upper}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <b>Audience</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormatRange
                                                    lower={
                                                        record.audience_lower
                                                    }
                                                    upper={
                                                        record.audience_upper
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <b>Impressions</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormatRange
                                                    lower={
                                                        record.impressions_lower
                                                    }
                                                    upper={
                                                        record.impressions_upper
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </>
    )
}

export default AdList
