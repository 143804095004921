import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Datagrid,
    Edit,
    EditGuesser,
    EmailField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    UrlField,
    useEditController,
    useGetManyReference,
    useRecordContext,
    useShowContext,
    useShowController,
} from "react-admin"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useEffect, useState } from "react"

import AdList from "../components/ad_list"
import AdsCandidate from "./ads_candidate"
import Box from "@mui/material/Box"
import { Container } from "../components/container"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { ItemDivider } from "../components/divider"
import ListActionsTopToolbar from "../components/list_actions"
import MenuItem from "@mui/material/MenuItem"
import { PostPagination } from "."
import Typography from "@mui/material/Typography"
import UserIcon from "@mui/icons-material/Group"
import { dataProvider } from "../App"

const candidatesFilter = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput
        source="electorate_id"
        label="Electorate"
        reference="electorates"
        sort={{ field: "name", order: "ASC" }}
        perPage={250}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <ReferenceInput
        source="party_id"
        label="Party"
        reference="partygroups"
        perPage={50}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <BooleanInput label="Indie Watch" source="indie_watch" alwaysOn />,
]

const FindFacebookLink = () => {
    const record = useRecordContext()

    let link = `https://google.com/search?q=${record.firstname}+${record.surname}+facebook`

    return (
        <a href={link} target="_new">
            Find Facebook (opens in new window)
        </a>
    )
}

const FindWebsiteLink = () => {
    const record = useRecordContext()

    let link = `https://google.com/search?q=${record.firstname}+${record.surname}+${record.electorate_id}`

    return (
        <a href={link} target="_new">
            Find Website (opens in new window)
        </a>
    )
}

const CandidatesList = () => (
    <Container>
        <Typography variant="h4">Candidates</Typography>
        <List
            pagination={<PostPagination />}
            perPage={50}
            filters={candidatesFilter}
            actions={<ListActionsTopToolbar />}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="surname" />
                <TextField source="firstname" />
                <ReferenceField
                    source="electorate_id"
                    reference="electorates"
                    perPage={250}
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    source="party_id"
                    reference="parties"
                    perPage={250}
                >
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="indie_watch" label="Watch Indie" />
                <UrlField source="facebook" />
                <TextField source="facebook_id" label={"Facebook Id"} />
                <TextField source="google_id" label={"Google Ad Id"} />
            </Datagrid>
        </List>
    </Container>
)

const CandidatesShow = (props) => {
    const { record, isLoading, error } = useShowController()
    const [ad_data, setAdData] = useState()
    const [ad_source, setAdSource] = useState("all")

    useEffect(() => {
        if (!record || !record.id) return
        dataProvider
            .getAdsList({
                candidate_id: record.id,
                pagination: { page: 1, perPage: 100 },
                sort: { field: "start_time", order: "DESC" },
                ad_source,
            })
            .then((data) => {
                setAdData(data)
            })
    }, [ad_source, record])

    const handleChange = (e) => {
        console.log(e)
        setAdSource(e.target.value)
    }

    const ad_sources = [
        { id: "facebook", name: "Facebook" },
        { id: "google", name: "Google" },
    ]

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (error) {
        return <div>Error!</div>
    }

    return (
        <Container>
            <Typography variant="h4">{record.name_full}</Typography>
            <Show title={record && record.name_full}>
                <SimpleShowLayout sx={{ maxWidth: 500, width: 500 }}>
                    <TextField source="id" disabled />
                    <TextField
                        source="firstname"
                        label="First Name"
                        fullWidth
                    />
                    <TextField source="middlename" fullWidth />
                    <TextField source="surname" fullWidth />
                    <BooleanField source="indie_watch" fullWidth />
                    <ItemDivider />
                    <BooleanField source="sitting_member" />
                    <ReferenceField
                        source="electorate_id"
                        reference="electorates"
                        perPage={250}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ItemDivider />
                    <UrlField source="facebook" fullWidth />
                    <UrlField source="instagram" />
                    <UrlField source="twitter" />
                    <TextField source="facebook_id" label={"Facebook ID"} />
                    <TextField source="google_id" label={"Google Ad ID"} />
                </SimpleShowLayout>
            </Show>
            <Typography
                variant="h4"
                style={{ marginTop: "2em", marginBottom: "1em " }}
            >
                Stats
            </Typography>
            {record.id && (
                <AdsCandidate cand_id={record.id} show_candidates={false} />
            )}
            <Typography
                variant="h4"
                style={{ marginTop: "2em", marginBottom: "1em " }}
            >
                Ads
            </Typography>
            <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                <FormControl fullWidth>
                    <InputLabel id="ad_source">Ad Source</InputLabel>
                    <Select
                        labelId="ad_source"
                        id="ad_source"
                        value={ad_source}
                        label="Ad Source"
                        onChange={handleChange}
                    >
                        <MenuItem key={-1} value={"all"}>
                            All
                        </MenuItem>
                        {ad_sources.map((d, i) => (
                            <MenuItem key={i} value={d.id}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {ad_data && <AdList records={ad_data} />}
        </Container>
    )
}

const CandidatesEdit = () => {
    const [google_ids, setGoogleIds] = useState([])
    const [facebook_ids, setFacebookIds] = useState([])
    const { record, isLoading, error } = useEditController()

    useEffect(() => {
        dataProvider.lookupSocial({ site: "google" }).then((r) => {
            console.log(r)
            setGoogleIds(r)
        })

        dataProvider.lookupSocial({ site: "facebook" }).then((r) => {
            console.log(r)
            setFacebookIds(r)
        })
    }, [])

    return (
        <Container>
            <Typography variant="h4">Edit Candidate</Typography>
            <Edit title={record && record.name_full}>
                <SimpleForm sx={{ maxWidth: 500 }}>
                    <TextInput source="id" disabled />
                    <TextInput source="firstname" fullWidth />
                    <TextInput source="middlename" fullWidth />
                    <TextInput source="surname" fullWidth />
                    <BooleanInput
                        source="indie_watch"
                        fullWidth
                        label="Indie Watch"
                    />
                    <ItemDivider />
                    <Typography variant="h6" style={{ marginTop: "2em" }}>
                        Electorate
                    </Typography>
                    <BooleanInput source="sitting_member" fullWidth />
                    <ReferenceInput
                        source="electorate_id"
                        reference="electorates"
                        perPage={100}
                    >
                        <SelectInput source="name" fullWidth />
                    </ReferenceInput>
                    <ItemDivider />
                    <Typography variant="h6" style={{ marginTop: "2em" }}>
                        Web
                    </Typography>
                    <FindWebsiteLink />
                    <TextInput source="website" fullWidth />
                    <ItemDivider />
                    <Typography variant="h6" style={{ marginTop: "2em" }}>
                        Socials
                    </Typography>
                    <FindFacebookLink />
                    <TextInput source="facebook" fullWidth />
                    <TextInput source="instagram" fullWidth />
                    <TextInput source="twitter" fullWidth />
                    {facebook_ids && (
                        <AutocompleteInput
                            source="facebook_id"
                            choices={facebook_ids}
                            fullWidth
                            label={"Facebook Ad Id"}
                        />
                    )}
                    <TextInput
                        source="facebook_id"
                        fullWidth
                        label={"Facebook Id"}
                    />
                    {google_ids && (
                        <AutocompleteInput
                            source="google_id"
                            choices={google_ids}
                            fullWidth
                            label={"Google Ad Id"}
                        />
                    )}
                </SimpleForm>
            </Edit>
        </Container>
    )
}

const settings = {
    list: CandidatesList,
    edit: CandidatesEdit,
    show: CandidatesShow,
    icon: UserIcon,
}

export default settings
