import * as React from "react"

import {
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from "react-admin"

import Box from "@mui/material/Box"
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed"
import SubMenu from "./submenu"
import ads from "../pages/ads"
import candidates from "../pages/candidates"
import electorates from "../pages/electorates"
import parties from "../pages/parties"
import partygroups from "../pages/partygroups"
import { useState } from "react"

const Menu = ({ dense = false }) => {
    const [state, setState] = useState({
        menuElectorates: true,
        menuParties: true,
        menuStats: true,
    })
    const [open] = useSidebarState()

    const handleToggle = (menu) => {
        console.debug(state, menu, state[menu])
        setState((state) => ({ ...state, [menu]: !state[menu] }))
    }

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 3,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle("menuElectorates")}
                isOpen={state.menuElectorates}
                name="Electorates"
                icon={<electorates.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/electorates"
                    state={{ _scrollToTop: true }}
                    primaryText={"Seats"}
                    leftIcon={<electorates.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/candidates"
                    state={{ _scrollToTop: true }}
                    primaryText={"Candidates"}
                    leftIcon={<candidates.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuParties")}
                isOpen={state.menuElectorates}
                name="Parties"
                icon={<parties.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/parties"
                    state={{ _scrollToTop: true }}
                    primaryText={"Parties"}
                    leftIcon={<parties.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/partygroups"
                    state={{ _scrollToTop: true }}
                    primaryText={"Party Groups"}
                    leftIcon={<partygroups.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuStats")}
                isOpen={state.menuStats}
                name="Ads"
                icon={<ads.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/stats/"
                    state={{ _scrollToTop: true }}
                    primaryText={"Latest"}
                    leftIcon={<DynamicFeedIcon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/stats/top"
                    state={{ _scrollToTop: true }}
                    primaryText={"Candidates"}
                    leftIcon={<candidates.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/stats/candidate"
                    state={{ _scrollToTop: true }}
                    primaryText={"Charts"}
                    leftIcon={<ads.icon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    )
}

export default Menu
