import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js"
import {
    ReferenceField,
    SelectInput,
    useDataProvider,
    useGetList,
    useGetMany,
    useGetOne,
} from "react-admin"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import { Container } from "../components/container"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Line } from "react-chartjs-2"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { dataProvider } from "../App"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Click on Legend to enable and disable fields",
        },
    },
}

const AdsCandidate = (props) => {
    // const dataProvider = useDataProvider()
    const { cand_id = 1351, show_candidates = true } = props

    const [chartData, setChartData] = useState(undefined)
    const [candidate_id, setCandidateId] = useState(cand_id)
    const [candidate_id_2, setCandidateId2] = useState(undefined)
    const [show_field, setShowField] = useState("spend")
    const [ad_source, setAdSource] = useState("facebook")

    const {
        data: candidateData,
        isLoading,
        error,
    } = useGetList("candidates", {
        sort: { field: "name_full", order: "ASC" },
        filter: { facebook_id: true },
        pagination: { page: 1, perPage: 1500 },
    })

    useEffect(() => {
        dataProvider
            .getOne("stats/candidate", {
                id: candidate_id,
                field: show_field,
                candidate: candidate_id_2,
                ad_source,
            })
            .then((r) => {
                console.log(r)

                setChartData(r.data)
            })
    }, [candidate_id, ad_source])

    const handleChange = (event) => {
        setCandidateId(event.target.value)
    }

    const handleChange2 = (event) => {
        setCandidateId2(event.target.value)
    }

    const handleChangeField = (event) => {
        setShowField(event.target.value)
    }

    const handleAdSourceChange = (e) => {
        setAdSource(e.target.value)
    }

    const ad_sources = [
        { id: "facebook", name: "Facebook" },
        { id: "google", name: "Google" },
    ]

    if (isLoading) {
        return <p>Loading</p>
    }

    if (error) {
        return <p>ERROR</p>
    }

    return (
        <Container>
            <Typography>Candidate Stats</Typography>
            {show_candidates && (
                <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                    <FormControl fullWidth>
                        <InputLabel id="candidate_id">Candidate</InputLabel>
                        <Select
                            labelId="candidate_id"
                            id="candidate_id"
                            value={candidate_id}
                            label="Candidate"
                            onChange={handleChange}
                        >
                            {candidateData.map((d, i) => (
                                <MenuItem key={i} value={d.id}>
                                    {d.name_full}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}
            <Box sx={{ minWidth: 120, maxWidth: 500, marginTop: "2em" }}>
                <FormControl fullWidth>
                    <InputLabel id="ad_source">Ad Source</InputLabel>
                    <Select
                        labelId="ad_source"
                        id="ad_source"
                        value={ad_source}
                        label="Ad Source"
                        onChange={handleAdSourceChange}
                    >
                        {ad_sources.map((d, i) => (
                            <MenuItem key={i} value={d.id}>
                                {d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {candidate_id && chartData && (
                <Line options={chartOptions} data={chartData} {...props} />
            )}
        </Container>
    )
}

export default AdsCandidate
