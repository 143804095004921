import * as React from "react"

import {
    AutocompleteInput,
    Datagrid,
    Edit,
    List,
    ReferenceField,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    UrlField,
    useEditController,
    useShowContext,
} from "react-admin"
import { useEffect, useState } from "react"

import { ColorField } from "../components/color-input"
import { Container } from "../components/container"
import GroupsIcon from "@mui/icons-material/Groups"
import Typography from "@mui/material/Typography"
import { dataProvider } from "../App"

const PartyGroupsList = () => (
    <List pagination={false} perPage={25}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="name" />
            <ColorField source="color" />
            <UrlField source="website" />
            <UrlField source="facebook" />
            <UrlField source="facebook_id" label="Facebook ID" />
            <UrlField source="google_id" label="Google Ad ID" />
        </Datagrid>
    </List>
)

const PartyGroupsEdit = () => {
    const [google_ids, setGoogleIds] = useState([])
    const [facebook_ids, setFacebookIds] = useState([])
    const { record } = useEditController()

    useEffect(() => {
        dataProvider.lookupSocial({ site: "google" }).then((r) => {
            console.log(r)
            setGoogleIds(r)
        })

        dataProvider.lookupSocial({ site: "facebook" }).then((r) => {
            console.log(r)
            setFacebookIds(r)
        })
    }, [])

    console.log(record)

    return (
        <Container>
            <Typography variant="h4">
                Edit Party Group {record && record.name}
            </Typography>
            <Edit title={record && record.name}>
                <SimpleForm>
                    <TextInput source="id" disabled />
                    <TextInput source="name" fullWidth />
                    <TextInput source="color" fullWidth />
                    <TextInput source="website" fullWidth />
                    <TextInput source="facebook" fullWidth />
                    {facebook_ids && (
                        <AutocompleteInput
                            source="facebook_id"
                            choices={facebook_ids}
                            fullWidth
                            label={"Facebook Ad Id"}
                        />
                    )}
                    <TextInput
                        source="facebook_id"
                        fullWidth
                        label={"Facebook Id"}
                    />
                    {google_ids && (
                        <AutocompleteInput
                            source="google_id"
                            choices={google_ids}
                            fullWidth
                            label={"Google Ad Id"}
                        />
                    )}
                    <TextInput source="google_id" label="Google ID" fullWidth />
                    <TextInput source="instagram" fullWidth />
                    <TextInput source="twitter" fullWidth />
                </SimpleForm>
            </Edit>
        </Container>
    )
}

const PartyGroupsShow = () => {
    const { record } = useShowContext()

    return (
        <Container>
            <Typography variant="h4">
                Party Group {record && record.name}
            </Typography>
            <Show title={record && record.name}>
                <SimpleShowLayout sx={{ maxWidth: 500, width: 500 }}>
                    <TextField source="id" disabled />
                    <TextField source="name" />
                    <ReferenceField
                        label="Party Group"
                        source="party_group_id"
                        reference="partygroups"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <UrlField source="website" />
                    <UrlField source="facebook" />
                    <UrlField source="instagram" />
                    <UrlField source="twitter" />
                    <TextField source="facebook_id" label={"Facebook ID"} />
                </SimpleShowLayout>
            </Show>
        </Container>
    )
}

const settings = {
    list: PartyGroupsList,
    edit: PartyGroupsEdit,
    show: PartyGroupsShow,
    icon: GroupsIcon,
}

export default settings
