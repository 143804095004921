import MuiContainer from "@mui/material/Container"
import React from "react"

export const Container = ({ children }) => (
    <MuiContainer
        maxWidth="xl"
        style={{ marginTop: "2em", marginBottom: "4em" }}
    >
        {children}
    </MuiContainer>
)
