import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import * as ReactColor from "react-color"
import {
  useRecordContext,
  FieldTitle,
  useResourceContext,
  useInput,
} from "react-admin"

import "./ColorInput.css"

export const ColorField = ({ source, className }) => {
  const record = useRecordContext()
  return record ? (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "20px",
          height: "20px",
          background: record[source],
          marginRight: "5px",
        }}
      />
      <span className={className}>{record[source]}</span>
    </div>
  ) : null
}

export const ColorInput = ({
  label,
  source,
  className,
  isRequired,
  picker,
  props,
}) => {
  const record = useRecordContext()
  const resource = useResourceContext()

  const [show, setShow] = useState(false)
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput(props)

  const handleChange = ({ hex }) => {
    field.onChange(hex)
    setShow(false)
  }

  const Picker = ReactColor[`${picker}Picker`]

  return (
    <div>
      <TextField
        {...field}
        margin="normal"
        onFocus={this.handleOpen}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        error={!!(isTouched && error)}
        className={className}
      />
      {show ? (
        <div className="ColorInput-popup">
          <div className="ColorInput-cover" onClick={() => setShow(false)} />
          <Picker color={field.value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}
