import * as React from "react"
import { Layout } from "react-admin"
import AppBar from "./appbar"
import Menu from "./menu"

const LayoutCustom = (props) => {
  return <Layout {...props} appBar={AppBar} menu={Menu} />
}

export default LayoutCustom
