import * as React from "react"

import Card from "@mui/material/Card"

export const ItemDivider = () => {
  return (
    <Card
      style={{
        height: 1,
        width: "100%",
        backgroundColor: "#607D8B",
      }}
    />
  )
}
