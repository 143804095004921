import * as React from "react"

import {
    Datagrid,
    DateInput,
    List,
    NumberField,
    Redirect,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin"

import { Container } from "../components/container"
import { PostPagination } from "."
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import Typography from "@mui/material/Typography"

const startOfYear = new Date("2021-01-01T00:00:00").toISOString().split("T")[0]
const dateToday = new Date().toISOString().split("T")[0]

const statsFilter = [
    <TextInput source="name_full" label="Search" alwaysOn />,
    <ReferenceInput
        source="name"
        label="Name"
        reference="candidates"
        sort={{ field: "name_full", order: "ASC" }}
        perPage={1000}
    >
        <SelectInput optionText="name_full" optionValue="id" />
    </ReferenceInput>,
    <ReferenceInput
        source="party"
        label="Party"
        reference="parties"
        perPage={50}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <ReferenceInput
        source="electorate"
        label="Electorate"
        reference="electorates"
        sort={{ field: "name", order: "ASC" }}
        perPage={500}
        alwaysOn
    >
        <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <DateInput
        source="from_date"
        label={"From Date"}
        defaultValue={startOfYear}
    />,
    <DateInput source="to_date" label={"To Date"} defaultValue={dateToday} />,
]

export const StatsList = () => (
    <Container>
        <Typography variant="h3">Social Media Spending</Typography>

        <List
            filters={statsFilter}
            pagination={<PostPagination />}
            perPage={25}
        >
            <Datagrid bulkActionButtons={false}>
                <ReferenceField source="id" reference="candidates">
                    <TextField source="name_full" />
                </ReferenceField>
                <TextField source="electorate" />
                <ReferenceField source=""></ReferenceField>
                <TextField source="party" />
                <NumberField source="active_ads" />
                <NumberField source="total_ads" />
                <NumberField source="spend_lower" />
                <NumberField source="spend_upper" />
                <NumberField source="audience_lower" />
                <NumberField source="audience_upper" />
            </Datagrid>
        </List>
    </Container>
)

const settings = {
    list: StatsList,
    options: { label: "Top Spending" },
    icon: QueryStatsIcon,
}

export default settings
