import * as React from "react"

import { Admin, CustomRoutes, Resource } from "react-admin"

import { API_ENDPOINT } from "./config"
import AdsCandidate from "./pages/ads_candidate"
import AdsLatest from "./pages/ads_latest"
import HomePage from "./pages/home"
import { Layout } from "./layout"
import { Route } from "react-router-dom"
import ads from "./pages/ads"
import apiClient from "./client"
import candidates from "./pages/candidates"
import electorates from "./pages/electorates"
import { lightTheme } from "./layout/themes"
import parties from "./pages/parties"
import partygroups from "./pages/partygroups"

export const dataProvider = apiClient(API_ENDPOINT)

const App = () => (
    <Admin
        dataProvider={dataProvider}
        dashboard={HomePage}
        title={"Infotorch Elecmon"}
        disableTelemetry
        theme={lightTheme}
        layout={Layout}
    >
        <Resource name="states" />
        <Resource name="electorates" {...electorates} />
        <Resource name="candidates" {...candidates} />
        <Resource name="parties" {...parties} />
        <Resource name="partygroups" {...partygroups} />
        <Resource name="stats" />
        <Resource name="stats/top" {...ads} />
        <Resource name="stats/trends" />
        <Resource name="stats/candidate" />
        <Resource name="stats/electorate" />
        <Resource name="lookup/google" />
        <CustomRoutes>
            <Route
                path="/stats"
                element={<AdsLatest />}
                name={"stats.latest"}
            />
            <Route
                path="/stats/candidate"
                element={<AdsCandidate />}
                name={"stats.candidate"}
            />
        </CustomRoutes>
    </Admin>
)

export default App
